
import { Component, Prop, Vue } from 'vue-property-decorator';
import CaseImage from '@/components/Case/CaseImage.vue';
import VLazyImage from 'v-lazy-image/v2';

@Component({
  components: {CaseImage, VLazyImage}
})
export default class FakeReward extends Vue {
  @Prop({type: String, default: ''}) borderType!: string;
  @Prop({type: String, default: 'case'}) rewardType!: string;
  @Prop({type: String, default: ''}) src!: string;
  @Prop({type: String, default: ''}) alt!: string;
}
