import Axios from 'axios';

import { MOCK_CREATE_PAYMENT } from '@/requests/Payment/createPayment/mockCreatePayment';
import { AvailablePaymentMethods } from '@/types/AvailablePaymentMethods';

export default async function createPayment(paymentMethod: AvailablePaymentMethods, amount: number, displayCurrency: string): Promise<{
    href?: string;
    error?: string
}> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_CREATE_PAYMENT;
    }

    let response;
    switch (true) {
        case paymentMethod === 'SKINSBACK':
        case paymentMethod === 'SKINIFY':
        case paymentMethod === 'OVERPAY':
        case paymentMethod === 'PAYSELECTION':
            response = await Axios.post(
                `/api/payment/create/${paymentMethod.toLocaleLowerCase()}`,
                {amount, displayCurrency}
            );
            break;
        case paymentMethod.includes('CRYPTOMUS'):
            response = await Axios.post('/api/payment/create/cryptomus', {
                amount,
                currency: displayCurrency,
                method: paymentMethod.split('-')[1]
            });
            break;
        case paymentMethod.includes('PAYPALYCH')
        || paymentMethod.includes('NICEPAY')
        || paymentMethod.includes('STREAMPAY'):
            const _paymentSystem = paymentMethod.split('-')[0].toLowerCase();
            const _currency = paymentMethod.split('-')[1];
            response = await Axios.post(`/api/payment/create/${_paymentSystem}`, {
                currency: _currency,
                amount,
                displayCurrency
            });
            break;
        default:
            const paymentSystem = paymentMethod.split('-')[0].toLowerCase();
            const method = paymentMethod.split('-')[1];
            const currency = paymentMethod.split('-')[2];

            response = await Axios.post(`/api/payment/create/${paymentSystem}`, {
                method,
                currency,
                amount,
                displayCurrency
            });
            break;
    }

    if (paymentMethod.includes('TRUSTYPAYS') && !response.data.error) {
        function postData(path, params, method) {

            // Create form
            const hidden_form = document.createElement('form');

            // Set method to post by default
            hidden_form.method = method || 'post';

            // Set path
            hidden_form.action = path;

            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const hidden_input = document.createElement
                    ('input');
                    hidden_input.type = 'hidden';
                    hidden_input.name = key;
                    hidden_input.value = params[key];

                    hidden_form.appendChild(hidden_input);
                }
            }

            document.body.appendChild(hidden_form);
            hidden_form.submit();
        }

        postData('https://secure.trustypays.com/payment/auth', response.data, 'post');

        return {};
    }

    const {href, error} = response.data;

    return {href, error};
}
