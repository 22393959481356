
import { Component, Vue } from 'vue-property-decorator';

import { VueAgile } from 'vue-agile';
import Button from '@/components/Buttons/Button.vue';

@Component({
  components: { VueAgile, Button }
})
export default class Banners extends Vue {
  agileOptions = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    navButtons: false,
    autoplay: true,
    autoplaySpeed: 6500,
    fade: true
  };

  get season() {
    return this.$store.state.season;
  }
}
