import { Country } from '@/types/PaymentCountry';

export const ENABLED_COUNTRIES: Country[] = [
    // {name: 'Австрия', code: 'at', currency: 'EUR'},
    {name: 'Азербайджан', code: 'az', currency: 'AZN'},
    {name: 'Беларусь', code: 'by', currency: 'BYN'},
    // {name: 'Германия', code: 'de', currency: 'EUR'},
    {name: 'Казахстан', code: 'kz', currency: 'KZT'},
    // {name: 'Литва', code: 'lt', currency: 'EUR'},
    // {name: 'Латвия', code: 'lv', currency: 'EUR'},
    // {name: 'Польша', code: 'pl', currency: 'EUR'},
    {name: 'Россия', code: 'ru', currency: 'RUB'},
    // {name: 'Румыния', code: 'ro', currency: 'EUR'},
    // {name: 'Словакия', code: 'sk', currency: 'EUR'},
    // {name: 'Словения', code: 'si', currency: 'EUR'},
    {name: 'Украина', code: 'ua', currency: 'UAH'},
    // {name: 'Эстония', code: 'ee', currency: 'EUR'},
    // {name: 'Чехия', code: 'cz', currency: 'EUR'},
    {name: 'Остальной мир', code: 'other', currency: 'EUR'},
];
