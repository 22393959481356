var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"event-no-auth-header",style:({
    ['--bg']:
      'url(' +
      require('@/assets/img/event/' + _vm.season + '/no-auth-header-bg.png') +
      ')',
    ['--bgMob']:
      'url(' +
      require('@/assets/img/event/' + _vm.season + '/no-auth-header--mob.png') +
      ')',
  })},[_c('img',{staticClass:"_left-art",attrs:{"src":require('@/assets/img/event/' + _vm.season + '/no-auth-header-left.png')}}),_c('img',{staticClass:"_right-art",attrs:{"src":require('@/assets/img/event/' + _vm.season + '/no-auth-header-right.png')}}),_c('div',{staticClass:"_fade"}),_c('div',{staticClass:"event-no-auth-header__text"},[_c('h1',{staticClass:"_row-1"},[_vm._v(_vm._s(_vm.seasonName))]),_c('h2',{staticClass:"_row-2"},[_vm._v("приключение")]),_vm._m(0),_c('p',{staticClass:"_text-pc"},[_vm._v("Проходи задания и получай предметы!")])]),_vm._m(1),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({ scale: 1.2, reset: false, mobile: false }),expression:"{ scale: 1.2, reset: false, mobile: false }"}],staticClass:"wrapper"},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({ scale: 1.2, reset: false, desktop: false }),expression:"{ scale: 1.2, reset: false, desktop: false }"}],staticClass:"event-no-auth-header__blocks"},[_c('div',{staticClass:"free-enter"},[_c('div',{staticClass:"free-enter__left"},[_c('span',[_vm._v("399")]),_c('img',{staticClass:"_coin",attrs:{"src":require('@/assets/img/event/coin.png')}})]),_c('div',{staticClass:"free-enter__right"},[_vm._v("Бесплатно")])]),(!_vm.user)?_c('LoginGeneralButton',{attrs:{"shadow":""}}):_c('Button',{staticClass:"_start",attrs:{"type":"orange","text":"Начать приключение","size":"l"},nativeOn:{"click":function($event){return _vm.$emit('startEvent')}}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"_text"},[_vm._v(" Проходи простые задания"),_c('br'),_vm._v(" Получай крутые предметы"),_c('br')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"overflow":"hidden","width":"1px","height":"1px"}},[_c('h2',[_vm._v(" Получай очень редкий лут в нашем бесплатном приключении Dota 2, приключение дота 2 с халявными арканами! ")]),_c('p',{staticClass:"_text"},[_vm._v(" Проходи простые задания"),_c('br'),_vm._v(" Получай бесплатные предметы"),_c('br'),_vm._v(" Поднимайся в топе игроков"),_c('br'),_vm._v(" Наслаждайся призами ")])])
}]

export { render, staticRenderFns }